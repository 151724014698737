.react-tags {
  position: relative;
  background: #ffffff;
  /* shared font styles */
  font-size: 1rem;
  /* clicking anywhere will focus the input */
  cursor: text;
  border: 1px solid #d0d5dd;
  border-radius: 8px;
  width: 100%;
  min-height: 30px;
  font-family: "Roboto400";
  box-shadow: 0px 1px 2px rgb(16 24 40 / 5%);
  font-size: 14px;
  padding: 0 0.3rem;
  color: #37474f;
  outline: none;
  overflow: scroll;
  max-height: 4.5rem;
  transition: max-height 0.3s ease-in-out;
}

.react-tags.is-active {
  transition: all 0.3s ease-in-out;
  max-height: 100vh;
  overflow: unset;
}

.react-tags.is-disabled {
  opacity: 0.75;
  background-color: #eaeef2;
  /* Prevent any clicking on the component */
  pointer-events: none;
  cursor: not-allowed;
}

.react-tags.is-invalid {
  border-color: #fd5956;
  box-shadow: 0 0 0 2px rgba(253, 86, 83, 0.25);
}

.react-tags__label {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.react-tags__list {
  /* Do not use display: contents, it's too buggy */
  display: inline;
  padding: 0;
}

.react-tags__list-item {
  display: inline;
  list-style: none;
}

.react-tags__tag {
  margin: 0.2rem;
  padding: 0.2rem 0.5rem;
  border: 0;
  border-radius: 3px;
  background: #eaeef2;
  /* match the font styles */
  font-size: inherit;
  line-height: inherit;
}

.react-tags__tag:hover {
  color: #ffffff;
  background-color: #4f46e5;
}

.react-tags__tag::after {
  content: "";
  display: inline-block;
  width: 0.65rem;
  height: 0.65rem;
  clip-path: polygon(
    10% 0,
    0 10%,
    40% 50%,
    0 90%,
    10% 100%,
    50% 60%,
    90% 100%,
    100% 90%,
    60% 50%,
    100% 10%,
    90% 0,
    50% 40%
  );
  margin-left: 0.5rem;
  font-size: 0.875rem;
  background-color: #7c7d86;
}

.react-tags__tag:hover::after {
  background-color: #ffffff;
}

.react-tags__combobox {
  display: inline-block;
  /* match tag layout */
  padding: 0.375rem 0.25rem;
  margin-bottom: 0.25rem;
  /* prevents autoresize overflowing the container */
  max-width: 100%;
}

.react-tags__combobox-input {
  /* prevent autoresize overflowing the container */
  max-width: 100%;
  /* remove styles and layout from this element */
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  background: none;
  /* match the font styles */
  font-size: inherit;
  line-height: inherit;
}

.react-tags__combobox-input::placeholder {
  color: #7c7d86;
  opacity: 1;
}

.react-tags__listbox {
  position: absolute;
  z-index: 100;
  top: calc(100% + 5px);
  /* Negate the border width on the container */
  left: -1px;
  right: -1px;
  max-height: 12.5rem;
  overflow-y: auto;
  background: #ffffff;
  border: 1px solid #afb8c1;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.1) 0 10px 15px -4px, rgba(0, 0, 0, 0.05) 0 4px 6px -2px;
}

.react-tags__listbox-option {
  padding: 0.375rem 0.5rem;
}

.react-tags__listbox-option:hover {
  cursor: pointer;
  background: #eaeef2;
}

.react-tags__listbox-option:not([aria-disabled="true"]).is-active {
  background: #4f46e5;
  color: #ffffff;
}

.react-tags__listbox-option[aria-disabled="true"] {
  color: #7c7d86;
  cursor: not-allowed;
  pointer-events: none;
}

.react-tags__listbox-option[aria-selected="true"]::after {
  content: "✓";
  margin-left: 0.5rem;
}

.react-tags__listbox-option[aria-selected="true"]:not(.is-active)::after {
  color: #4f46e5;
}

.react-tags__listbox-option-highlight {
  background-color: #ffdd00;
}
