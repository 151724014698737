.docker-ctrl-controller {
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.info-container{
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}

.table-container {
  max-height: 20vw;
  overflow: auto;
  scrollbar-width: thin;
  /* Firefox */
  scrollbar-color: #888 #f1f1f1;
}

.table-container::-webkit-scrollbar {
  width: 5px;
  /* Width of the scrollbar */
}

.table-container::-webkit-scrollbar-track {
  background: #f1f1f1;
  /* Background color of the track */
}

.table-container::-webkit-scrollbar-thumb {
  background: #888;
  /* Color of the scrollbar handle */
}

.table-container::-webkit-scrollbar-thumb:hover {
  background: #555;
  /* Color of the scrollbar handle on hover */
}


.docker-ctrl-table {
  width: 100%;
  background-color: white;
  color: black;
  border-collapse: collapse;
  border-radius: 0;
  padding: 2%;
  font-family: "Poppins500";
  font-size: 10px;
  overflow-y: scroll;
}



.docker-ctrl-table thead {
  padding: 1%;

  font-size: 12px;
  text-align: left;
  font-family: "Poppins700";
  border-bottom: 1px solid #c4c4c4;
}

.docker-ctrl-table th {
  font-size: 12px;


  padding: 1%;
}

.docker-ctrl-table tr {
  font-size: 12px;


  padding: 1%;
}

.docker-ctrl-table td {
  font-size: 12px;


  padding: 1%;
}

.btngrp {
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
}